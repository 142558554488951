<script>
const elTransition = '0.3s height ease-in-out, 0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out';

export default {
  name: 'CollapseTransition',
  functional: true,
  render(h, { children }) {
    const data = {
      on: {
        beforeEnter(el) {
          el.style.transition = elTransition;
          if (!el.dataset) el.dataset = {};

          el.style.height = '0';
          el.style.paddingTop = 0;
          el.style.paddingBottom = 0;
        },

        enter(el) {
          el.dataset.oldOverflow = el.style.overflow;
          if (el.scrollHeight !== 0) {
            el.style.height = el.scrollHeight + 'px';
          } else {
            el.style.height = '';
          }
          el.style.paddingTop = '';
          el.style.paddingBottom = '';
          el.style.overflow = 'hidden';
        },

        afterEnter(el) {
          el.style.transition = '';
          el.style.height = '';
          el.style.overflow = el.dataset.oldOverflow;
        },

        beforeLeave(el) {
          if (!el.dataset) el.dataset = {};
          el.dataset.oldOverflow = el.style.overflow;
          el.dataset.oldHeight = el.style.height;
          el.style.height = el.scrollHeight + 'px';
          el.style.overflow = 'hidden';
        },

        leave(el) {
          if (el.scrollHeight !== 0) {
            el.style.transition = elTransition;
            el.style.height = '0';
            el.style.paddingTop = 0;
            el.style.paddingBottom = 0;
          }
        },

        afterLeave(el) {
          el.style.transition = '';
          el.style.height = '';
          el.style.overflow = el.dataset.oldOverflow;
          el.style.paddingTop = '';
          el.style.paddingBottom = '';
        }
      }
    };

    return h('transition', data, children);
  }
};
</script>