<template>
    <div class="content">
        <div class="digitalCloning">
            <div class="title">数字克隆</div>
            <div class="dcItem">
                <div class="item" v-for="(item, index) in dcList" :key="index">
                    <div @click="clickPath(item)">
                        <div class="img"><img :src="item.img" alt=""></div>
                        <div class="text1">{{ item.text1 }}</div>
                        <div class="text2">{{ item.text2 }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="myImage">
            <div class="title">我的形象</div>
            <div class="miItem" v-if="dmdcMyList.length !== 0">
                <div class="item" v-for="(item, index) in dmdcMyList" :key="index">
                    <imageModule :item="item" :controlsIs="true" :index="index" :newName="newName"
                        @deleteItem="deleteItem" @editName="editName" @refreshItem="refreshItem"  @videoItem="videoItem" />
                </div>
                <div>
                    <el-dialog title="重命名" :visible.sync="nameBoxShow" width="20%">
                        <el-input placeholder="请输入名称" v-model="nameValue"></el-input>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="nameBoxShow = false">取 消</el-button>
                            <el-button type="primary" @click="verifyBut">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
            </div>
            <div v-if="dmdcMyList.length == 0">
                <noData />
            </div>
        </div>
        <div class="publicImage">
            <div class="title">公共形象</div>
            <div class="piItem" v-if="dedcComList.length !== 0">
                <div class="item" v-for="(item, index) in dedcComList" :key="index">
                    <imageModule :item="item" @videoItem="videoItem" />
                </div>
            </div>
            <div v-if="dedcComList.length == 0">
                <noData />
            </div>
        </div>
        <div>
            <el-dialog :title="playVideoItem.name" :visible.sync="videoShow" :before-close="videoShowBefore"
                width="400px">
                <playVideo :item="playVideoItem" :videoShow="videoShow" />
            </el-dialog>
        </div>
    </div>
</template>
<script>
import imageModule from '../../../components/digitalImageModule/imageModule.vue';
import noData from '../../../components/noData.vue'
import playVideo from '../../../components/playVideo.vue'


import { getMyModelList, getComModelList, editMyMode } from '../../../api/api'
export default {
    components: {
        imageModule,
        noData,
        playVideo
    },
    data() {
        return {
            dcList: [
                {
                    text1: 'V1数字人普通克隆',
                    text2: '基本还原人物口型特征',
                    img: require('../image/item1.png'),
                    path: '/ImageCloning',
                    name: 'ImageCloning',
                    type: 'v1'
                },
                {
                    text1: 'V3数字人快速克隆',
                    text2: '无限并发，克隆速度加快',
                    img: require('../image/item2.png'),
                    path: '/ImageCloning',
                    name: 'ImageCloning',
                    type: 'v3'

                },
                // {
                //     text1: 'V3数字人精品克隆',
                //     text2: '复刻人物表现的细节神态',
                //     img: require('../image/item3.png'),
                //     path: '/ImageCloning',
                //     type: 'v3'
                // },
                // {
                //     text1: 'V4数字人极速克隆',
                //     text2: '快速合成数字人，马上体验',
                //     img: require('../image/item4.png'),
                //     path: '/ImageCloning',
                //     type: 'v4'
                // },
                // {
                //     text1: 'V5数字人真实克隆',
                //     text2: '一比一还原真人动态神情',
                //     img: require('../image/item5.png'),
                //     path: '/ImageCloning',
                //     name: 'ImageCloning',
                //     type: 'v5'
                // }
            ],
            dmdcMyList: [],
            dedcComList: [],
            nameBoxShow: false,//修改名称弹框
            nameValue: '',//修改名称value
            itemIndex: '',//确认修改的item的index
            newName: '',//修改的名称
            videoShow:false,
            playVideoItem:{}
        }
    },
    provide() {
        return {
            getMyModelList: this.getMyModelList
        };
    },
    methods: {
        videoItem(e){
            this.playVideoItem = e
            this.videoShow = true
        },
        videoShowBefore() {
            this.videoShow = false
        },
        clickPath(item) {
            if (!localStorage.getItem('token')) {
                this.$bus.$emit('demand', 'goLogin')
                return
            }
            if(item.type === 'v2'){
                this.$message({
                    message: '线路已下架',
                    type: 'warning'
                });
                return
            }
            if ( item.type === 'v4') {
                this.$message({ message: '功能暂未开放', type: 'error' });
                return
            }
            this.$router.push({ name: item.name, query: { type: item.type } })
        },
        // 删除我的作品
        deleteItem(index) {
            this.dmdcMyList.splice(index, 1)
        },

        // 刷新
        refreshItem(e) {
            let data = JSON.parse(e)
            let list = this.dmdcMyList
            for(let i=0;i<list.length;i++){
                if(list[i].id == data.id){
                    this.dmdcMyList[i] = data
                }
            }
            this.$forceUpdate();
        },

        // 修改名称
        editName(index) {
            if (index !== '') {
                this.nameBoxShow = true
            }
            this.itemIndex = index
        },
        // 确认修改名称
        verifyBut() {
            let index = this.itemIndex
            this.dmdcMyList[index].name = this.nameValue
            this.newName = this.nameValue
            this.editMyMode()
            this.nameValue = ''
            this.nameBoxShow = false
        },
        editMyMode() {
            let data = {
                id: this.dmdcMyList[this.itemIndex].id,
                name: this.nameValue
            }
            editMyMode(data).then(res => {
                if (res.data.code == 10000) {
                    this.$message({ message: '修改成功', type: 'success' });
                }
            })
        },

        getMyModelList() {// 获取我的形象
            let params = {
                page: 1,
                page_size: 8
            }
            getMyModelList(params).then(res => {
                if (res.data.code == 10000) {
                    this.dmdcMyList = res.data.data
                }
            })
        },
        getComModelList() {// 获取公共形象
            let params = {
                page: 1,
                page_size: 8
            }
            getComModelList(params).then(res => {
                if (res.data.code == 10000) {
                    this.dedcComList = res.data.data
                }
            })
        }
    },
    created() {
        this.getComModelList()//获取公共形象
        window.myData = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
        if (localStorage.getItem('token') == null) return
        this.getMyModelList()//获取我的形象
    },
}
</script>
<style lang="less" scoped>
.content {
    padding: 20px;

    .digitalCloning {

        .title {
            font-size: 22px;
            color: #3D3D3D;
        }

        .dcItem {
            margin-top: 20px;
            display: flex;
            color: #3D3D3D;

            // justify-content: space-between;
            .item {
                padding: 28px 18px;
                border: 1px solid #CAD1FF;
                border-radius: 10px;
                margin-left: 20px;
                cursor: pointer;

                .text1 {
                    margin-top: 10px;
                    font-size: 20px;
                    line-height: 28px;
                }

                .text2 {
                    font-size: 10px;
                    line-height: 22px;
                }

                .img {
                    width: 196px;
                    height: 197px;
                }
            }

            .item:hover {
                box-shadow: 0 0 6px 4px #e5e2e4;
            }
        }
    }

    .myImage {
        margin-top: 20px;

        .title {
            font-size: 22px;
            color: #3D3D3D;
        }

        .miItem {
            display: flex;
            flex-wrap: wrap;

            .item {
                margin-top: 10px;
                width: 375px;

            }
        }
    }

    .publicImage {
        margin-top: 40px;

        .title {
            font-size: 22px;
            color: #3D3D3D;
        }

        .piItem {
            display: flex;
            flex-wrap: wrap;

            .item {
                margin-top: 10px;
                width: 375px;

            }
        }
    }
}
</style>