import { render, staticRenderFns } from "./playVideo.vue?vue&type=template&id=58d0d462&scoped=true"
import script from "./playVideo.vue?vue&type=script&lang=js"
export * from "./playVideo.vue?vue&type=script&lang=js"
import style0 from "./playVideo.vue?vue&type=style&index=0&id=58d0d462&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d0d462",
  null
  
)

export default component.exports