<template>
    <div class="myWork">
        <!-- <div>我的作品</div> -->
        <div class="tab">
            <div class="item" @click="clickTab(index)"
                :style="{ background: (tabIndex == index ? item.back : ''), color: (tabIndex == index ? '#fff' : '#000') }"
                v-for="(item, index) in tab" :key="index">
                {{ item.name }}
            </div>
        </div>

        <!-- 视频列表 -->
        <div class="myVideoList">
            <div class="search">
                <!-- <div style=" display: flex;justify-content: right;"> -->
                <el-input placeholder="请输入内容" @change="search" prefix-icon="el-icon-search" v-model="searchValue">
                </el-input>
                <!-- </div> -->
            </div>
            <div class="videoList" v-if="list.length !== 0">
                <!--  -->
                <div class="item" v-for="(item, index) in list" :key="index">
                    <worksModule  :downloadShow="true" :item="item" :controlsIs="true" :index="index" @refreshItem="refreshItem" :newName="newName"
                        @deleteItem="deleteItem" @editName="editName" @videoItem="videoItem" @vTtpe="false" />
                </div>
                <!-- 修改名称弹框 -->
                <div>
                    <el-dialog title="重命名" :visible.sync="nameBoxShow" width="20%">
                        <el-input placeholder="请输入名称" v-model="nameValue"></el-input>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="nameBoxShow = false">取 消</el-button>
                            <el-button type="primary" @click="verifyBut">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
            </div>
            <div class="noData" v-if="list.length == 0">
                <noData />
            </div>
            <div class="paging" v-if="total > 12">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="page"
                    :page-size="12" :total="total" :page-sizes="[12]" @current-change="handlePageChange" />
            </div>
        </div>

        <div>
            <el-dialog :title="playVideoItem.name" :visible.sync="videoShow" :before-close="videoShowBefore"
                width="400px">
                <playVideo :item="playVideoItem" :videoShow="videoShow" />
            </el-dialog>
        </div>
    </div>
</template>
<script>
import worksModule from '../../components/digitalImageModule/worksModule.vue';
// 获取作品-修改作品
import { getMyImageList, editMyWorkVideo } from '../../api/api'
import noData from '../../components/noData.vue'
import playVideo from '../../components/playVideo.vue';
export default {
   
    components: {
        worksModule,
        noData,
        playVideo
    },
    data() {
        return {
            tab: [
                {
                    name: 'V1作品',
                    back: 'linear-gradient(90deg, #19B9FB 0%, #92CFE8 100%), linear-gradient(270deg, #757ABF 0%, #8583BE 60%, #EAB0D1 100%), #D8D8D8',
                    type: 1
                },
                {
                    name: 'V2作品',
                    back: 'linear-gradient(90deg, #4183F5 0%, #51B1DE 100%',
                    type: 2
                },
                {
                    name: 'V3作品',
                    back: 'linear-gradient(90deg, #50C4B8 0%, #29B8FB 100%, #D8D8D8',
                    type: 3
                },
                {
                    name: 'V4作品',
                    back: 'linear-gradient(270deg, #3F8CFF 0%, #5376FF 99%), #D8D8D8',
                    type: 4
                },
                {
                    name: 'V5作品',
                    back: 'linear-gradient(270deg, #46B2FF 0%, #9E7DFF 99%), #D8D8D8',
                    type: 5
                },
            ],
            list: [],
            tabIndex: '0',//选中的tab的Index
            searchValue: '',//搜索
            nameBoxShow: false,//修改名称弹框
            nameValue: '',//修改名称value
            itemIndex: '',//确认修改的item的index
            newName: '',//修改的名称
            playVideoItem: {},//播放作品
            videoShow: false,//播放视频弹框
            line_id: '1',
            page: 1,//作品分页
            total: 0,//作品总数
            fullscreenLoading: false//加载

        }
    },
    provide() {
        return {
            getMyImageList: this.getMyImageList
        };
    },
    methods: {
        clickTab(index) {

            if (index == 3) {
                this.$message({
                    message: '敬请期待！',
                    type: 'success'
                });
                return
            }
            this.tabIndex = index
            this.line_id = this.tab[index].type
            this.searchValue = ''
            this.getMyImageList()

            if (index == 4) {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                setTimeout(() => {
                    loading.close();
                }, 2000);
            }
        },
        videoShowBefore() {
            this.videoShow = false
        },
        videoItem(e) {
            this.playVideoItem = e
            this.videoShow = true
            console.log(this.playVideoItem)
        },
        // 搜索
        search() {
            this.page = 1
            this.getMyImageList()
        },
        // 删除我的作品
        deleteItem(index) {
            this.list.splice(index, 1)
        },

        // 刷新数据
        refreshItem(e){
            let data = JSON.parse(e)
            let list = this.list
            for(let i=0;i<list.length;i++){
                if(list[i].id == data.id){
                    this.list[i] = data
                }
            }
            this.$forceUpdate()
        },


        // 修改名称
        editName(index) {
            console.log(index, 'index')
            if (index !== '') {
                this.nameBoxShow = true
            }
            this.itemIndex = index
        },
        // 确认修改名称
        verifyBut() {
            this.editMyWorkVideo()
        },
        handlePageChange(currentPage) {

            this.page = currentPage
            this.getMyImageList()
            // 在此刷新数据
        },
        editMyWorkVideo() {
            let data = {
                id: this.list[this.itemIndex].id,
                name: this.nameValue
            }
            editMyWorkVideo(data).then(res => {
                if (res.data.code == 10000) {
                    let index = this.itemIndex
                    this.list[index].name = this.nameValue
                    this.newName = this.nameValue
                    this.nameValue = ''
                    this.nameBoxShow = false
                    this.$message({ message: '修改成功', type: 'success' });
                }
            })
        },
        // 获取我的作品-视频
        getMyImageList() {
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

            let params = {
                page: this.page,
                page_size: 12,
                line_id: this.line_id,
                name: this.searchValue
            }
            getMyImageList(params).then(res => {
                if (res.data.code == 10000) {
                    this.total = res.data.total
                    this.list = res.data.data
                    loading.close();
                } else {
                    loading.close();
                }
            })
        },
    },
    created() {
        if (this.$route.query.type) {
            let type = this.$route.query.type
            this.line_id = type
            this.tabIndex = type - 1
        }
        this.getMyImageList()
    }
}
</script>
<style lang="less" scoped>
.myWork {
    border-radius: 20px;
    height: 840px;
    background: linear-gradient(239deg, rgba(255, 255, 255, 0.32) 1%, rgba(238, 182, 241, 0.11) 24%, rgba(62, 122, 254, 0.06) 47%, rgba(142, 186, 226, 0.16) 66%, rgba(151, 184, 255, 0.2784) 98%);
}

.tab {
    display: flex;
    padding: 30px 20px;

    .item {
        width: 107px;
        height: 39.5px;
        line-height: 39.5px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        /*悬停变小手的属性*/
        border-radius: 10px;
        background-color: #D8D8D8;
        margin-right: 20px;
        color: #3D3D3D;
    }
}

.myVideoList::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}

.myVideoList {
    height: 700px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.15);
    margin: 0 20px;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .search {
        padding-top: 20px;
        margin-left: 30px;

        .el-input {
            width: 214px;
        }
    }

    .videoList {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .item {
            width: 375px;
            display: flex;
            justify-content: center;
        }
    }

    .paging {
        position: sticky;
        bottom: 0;
        left: 0;
        height: 50px;
        background-color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -60px;
        margin-top: -70px;
    }
}

.el-popper {
    .delete {
        text-align: center;
        cursor: pointer;
    }
}
</style>