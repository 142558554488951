<template>
    <div class="digitalman">
        <div class="tab">
            <div class="item" :class="{ active: tabIndex == index ? 'active' : '' }" @click="selectTab(item.path, index)"
                v-for="(item, index) in navItems" :key="index">
                <div>{{ item.title }}</div>
            </div>
        </div>
        <div>
            <router-view />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            navItems: [
                { title: '数字克隆', path: '/Home/digitalMan/digitalCloning', },
                { title: '我的形象', path: '/Home/digitalMan/myImage', },
                { title: '公共形象', path: '/Home/digitalMan/comImage', },
            ],
            tabIndex: 0,
        }
    },
    methods: {
        selectTab(path, index) {

            this.$router.push({ path: path })
            if (index == 1) {
                if (!localStorage.getItem('token')) {
                    this.$bus.$emit('demand', 'goLogin')
                    return
                }
            }
            this.tabIndex = index;
        },
    },

}
</script>
<style lang="less" scoped>
.digitalman::-webkit-scrollbar {
    display: none
}

.digitalman {
    background-color: #EAF2FD;
    border-radius: 20px;
    overflow-y: auto;
    height: 840px;
}

.tab {
    display: flex;
    padding: 20px;

    .active {
        font-weight: bold;
        border-bottom: 2px solid #000;

        .text {
            color: #000 !important;
        }
    }

    .item {
        padding: 15px 0;
        margin-right: 30px;
        font-size: 26px;
        cursor: pointer;
        transition: background-color 0.3s;
        white-space: nowrap;

        .text {
            color: #939393;
            text-decoration: none;
            /* 去除默认的下划线 */
            outline: none;
            /* 去除旧版浏览器的点击后的外虚线框 */
        }
    }
}
</style>