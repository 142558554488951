<template>
    <div class="broadcastCopy">
        <div style="padding: 0 20px;">
            <Header />
        </div>
        <div class="contentBox">
            <div class="headerTitle">产品营销</div>
            <div class="randomExample">不知道该写什么，试试<span>随机示例</span></div>
            <div class="allWorks">
                <div>查看全部作品</div>
            </div>
            <!--  -->
            <div class="tradeName">
                <div class="titel">商品名称</div>
                <div class="input"><el-input v-model="nameValue" placeholder="请输入你的商品名称"></el-input></div>
            </div>
            <!--  -->
            <div class="themeTtile">其他说明</div>
            <div class="textareaBox">
                <el-input type="textarea" resize="none" placeholder="例如：商品特点、优惠活动" v-model="textarea" maxlength="200"
                    show-word-limit>
                </el-input>
            </div>
            <!--  -->
            <div class="generate" @click="generate" v-loading.fullscreen.lock="fullscreenLoading">生 成</div>
        </div>

        <el-drawer custom-class="drawer" :wrapperClosable="false"  size="1000px" :visible.sync="drawer" direction="rtl">
            <!-- 标题框 -->
            <div class="title">{{ titleValue }}</div>
            <!-- 内容 -->
            <div class="rextrea">
                <div class="textBox"></div>
                <div class="number_copy">
                    <div class="number">字数:{{ rextrea.length }}</div>
                    <div class="copy" @click="copy">复制全文</div>
                </div>
            </div>
            <!--  -->
            <div class="tips">
                <div><img src="../../assets/gantanhao.png" alt=""></div>
                <div>内容为AI生成，你可以自定义修改或增减，您对该内容的使用应严格遵循国家相关法律法规的规定</div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
    components: {
        Header
    },
    data() {
        return {
            nameValue:'',//商品名称
            textarea: '',//其他说明
           
            drawer: false,
            titleValue: '',//抽屉标题
            rextrea: '',//抽屉文本内容
            fullscreenLoading: false
        }
    },
    methods: {

        generate() {
            this.fullscreenLoading = true;//打开加载
            setTimeout(() => {
                this.fullscreenLoading = false;//关闭加载
                this.drawer = true
            }, 2000);
          
        },
        async copy(val) {
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                this.$message.success('复制成功')
                return navigator.clipboard.writeText(val)
            } else {
                // 创建text area
                const textArea = document.createElement('textarea')
                textArea.value = val
                // 使text area不在viewport，同时设置不可见
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()
                this.$message.success('复制成功')
                return new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? res() : rej()
                    textArea.remove()
                })
            }
        },
    }
}
</script>
<style lang="less" scoped>
.contentBox {
    margin: 0 20px 20px 20px;
    background-color: #fff;
    border-radius: 20px;
    height: 830px;
}

.headerTitle {
    height: 81px;
    line-height: 81px;
    text-align: center;
    border-bottom: 1px solid #E7E7E7;
    font-size: 24px;
    color: #3D3D3D;
    position: sticky;
    top: 0;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
}

.randomExample {
    padding: 0 20px;
    line-height: 40px;
    font-size: 24px;
    color: #3D3D3D;

    span {
        color: #549AFE;
        cursor: pointer;
    }
}

.allWorks {
    padding: 0 20px;
    display: flex;
    justify-content: right;

    div {
        cursor: pointer;
        padding: 5px 10px;
        font-size: 20px;
        color: #FFFFFF;
        border-radius: 5px;
        background: #549AFE;
    }
}
.tradeName{
    margin-bottom: 20px;
    .titel{
        font-size: 24px;
        color: #3D3D3D;
        margin-bottom: 10px;
    }
    padding: 0 20px;
    width: 1133px;
}

.themeTtile {
    padding: 0 20px;
    font-size: 24px;
    color: #3D3D3D;
}

.textareaBox {
    padding: 20px;
    width: 1133px;

    /deep/.el-textarea__inner {

        height: 375px;
    }
}


.generate {
    margin-left: 20px;
    margin-top: 20px;
    width: 1133px;
    height: 82px;
    line-height: 82px;
    text-align: center;
    border-radius: 10px;
    background: #D8D8D8;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

/deep/.drawer {
    .el-drawer__body {
        padding: 0 20px;
    }

    .title {
        padding: 0 20px;
        border-radius: 10px;
        border: 1px solid #909090;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        color: #3D3D3D;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .rextrea {
        margin-top: 80px;
        padding: 0 20px;
        border-radius: 10px;
        border: 1px solid #909090;
        height: 680px;

        .textBox {
            margin-top: 20px;
            border-radius: 10px;
            // border: 1px solid #909090;
            height: 600px;
            scrollbar-width: none;
            /* Firefox */
            -ms-overflow-style: none;
            /* IE 10+ */
            overflow-x: hidden;
            overflow-y: auto;
            font-size: 18px;
            line-height: 25px;
        }

        .number_copy {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .number {
                font-size: 20px;
                color: #CCCACA;
            }

            .copy {
                padding: 5px 10px;
                color: #fff;
                font-size: 20px;
                background-color: #549AFE;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }

    .tips {
        display: flex;
        justify-content: right;
        line-height: 18px;
        margin-top: 15px;
        font-size: 16px;
        color: #CCCACA;

        img {
            margin-right: 5px;
            width: 18px;
            height: 18px;
        }
    }
}
</style>