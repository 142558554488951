<template>
    <div class="imagedataBank">

        <div class="search_append">
            <div class="search"></div>
            <div class="append">
                <div @click="dialogShow = true">上传到资料库</div>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">

                <div class="video_item">
                    <video class="video" :autoplay="false" :src="item.url"></video>
                </div>

                <div class="name_delete">
                    <div class="name">
                        {{ item.name }}
                    </div>
                    <div class="delete" @click="deleteImage(item, index)">删除</div>
                </div>

                <div class="select">
                    <div class="but" @click="selectBut(item)">选择</div>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <div class="pading">
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="page"
                    :page-size="12" :total="total" :page-sizes="[12]" @current-change="handleSizeChange" />
        </div>



        <el-dialog title="上传视频到资料库" append-to-body :visible.sync="dialogShow" width="30%">
            <div class="name">
                <div class="title">名称</div>
                <div><el-input v-model="nameValue" maxlength="10" placeholder="请输入视频名称"></el-input></div>
            </div>
            <div class="uploadImage" v-if="!ossUrl">
                <videoUpload :showProgress="showProgress" ref="videoUpload" @videoPath="videoPath"
                    @videoDuration="videoDuration" />

            </div>

            <div class="ossImage" v-if="ossUrl">

                <div class="video">
                    <div class="videoBox"><video id="myVideo" :src="ossUrl" controls></video></div>
                    <div class="reupload" @click="reupload">
                        <div class="text">重新上传视频</div>
                    </div>
                </div>

            </div>


            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="uploadImage">上 传</el-button>
            </span>
        </el-dialog>


    </div>
</template>
<script>
import { getImagedataBank, dataBankDetele, uploadDataBank } from '../../../api/api'
import videoUpload from '../../../components/videoUpload.vue'//视频上传 - v5使用
export default {
    components: {
        videoUpload
    },
    data() {
        return {
            list: [],
            total: 0,
            page: 1,
            dialogShow: false,
            nameValue: '',//视频名称
            showProgress: false,//重新上传视频-处理上一条视频

            ossUrl: '',//上传后返回的视频地址
            duration: ''
        }
    },
    methods: {
        // 视频时长
        videoDuration(e) {
            this.duration = e
        },
        // 视频链接
        videoPath(e) {
            this.ossUrl = e
        },
        // 重新上传视频
        reupload() {
            this.ossUrl = ''
            this.showProgress = false
        },

        handleSizeChange(e) {
            this.page = e
            this.getImagedataBank()
        },

        deleteImage(item, index) {

            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: item.id
                }
                dataBankDetele(data).then(res => {
                    if (!res) return
                    if (res.data.code == 10000) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.list.splice(index, 1)
                        this.getImagedataBank()
                    }
                })
            }).catch(() => {
            });
        },

        // 选择视频
        selectBut(item){
            this.$emit('selectdataBankImageItem',item)
        },

        // 上传视频
        uploadImage() {
            if (!this.nameValue) return this.$message.error('请输入视频名称');
            if (!this.ossUrl) return this.$message.error('请选择视频');
            let data = {
                name: this.nameValue,
                url: this.ossUrl,
                file_type: 3,
                duration: this.duration
            }
            uploadDataBank(data).then(res => {
                if (!res) return
                if (res.data.code == 10000) {
                    this.$message({
                        message: '上传成功',
                        type: 'success'
                    });
                }
                this.dialogShow = false
                this.getImagedataBank()
            })
        },

        getImagedataBank() {
            let parse = {
                file_type: 3,
                keyword: '',
                per_page: 12,
                page: this.page
            }
            getImagedataBank(parse).then(res => {
                if (!res) return
                if (res.data.code == 10000) {
                    this.total = res.data.total
                    this.list = res.data.data
                }
            })
        }
    },
    created() {
        this.getImagedataBank()
    }
}
</script>
<style lang="less" scoped>
.imagedataBank {}

.search_append {
    display: flex;
    justify-content: space-between;

    .append {
        background-color: #279CEF;
        padding: 10px 20px;
        color: #fff;
        border-radius: 10px;
        cursor: pointer;

    }
}

.list {
    display: flex;
    flex-wrap: wrap;

    .item {
        margin: 10px;
        width: 280px;

        .video_item {
            background-color: #000;
            height: 185px;

            .video {
                width: 280px;
                height: 185px;
            }
        }

        .name_delete {
            display: flex;
            justify-content: space-between;
            padding: 5px 20px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
            border-radius: 0 0 10px 10px;

            .name {
                max-width: 150px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .delete {
                cursor: pointer;
            }
        }

        .select {
            margin-top: 10px;
            height: 40px;
            line-height: 40px;
            display: flex;
            justify-content: center;

            .but {
                max-width: 200px;
                background: linear-gradient(270deg, #00E5FF 0%, #4DD0E1 50%, #8C9EFF 100%), #EFEFEF;
                color: #fff;
                padding: 0 30px;
                text-align: center;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
}
.pading{
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.uploadImage {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.ossImage {
    margin-top: 30px;

    .videoBox {
        display: flex;
        justify-content: center;

        video {
            width: 521px;
            height: 150px;
        }
    }

    .reupload {
        display: flex;
        justify-content: center;

        .text {
            cursor: pointer;
            /*悬停变小手的属性*/
            width: 521px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: #3E7AFE;
            font-size: 20px;
            background-color: #fff;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
            border-radius: 0px 0px 5px 5px;
        }

    }
}
</style>