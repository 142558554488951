<template>
    <div class="TitleGeneration">
        <div style="padding: 0 20px;">
            <Header />
        </div>
        <div class="contentBox">
            <div class="headerTitle">标题生成</div>
            <div class="randomExample">不知道该写什么，试试<span>随机示例</span></div>
            <div class="allWorks">
                <div>查看全部作品</div>
            </div>
            <!--  -->
            <div class="themeTtile">原文</div>
            <div class="generationRegion">
                <div class="left">
                    <div class="textarea">
                        <el-input type="textarea" resize="none" placeholder="请输入内容" v-model="textarea" maxlength="200"
                            show-word-limit>
                        </el-input>
                    </div>

                    <div class="numberHeadings">
                        <div class="title">标题数量</div>
                        <div class="options">
                            <div class="item" @click="selectOption(index)" :class="{ active: optionIndex === index }"
                                v-for="(item, index) in optionsList" :key="index">{{ item.name }}</div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="generate" @click="generate" v-loading.fullscreen.lock="fullscreenLoading">生 成</div>
                </div>
                <!-- 生成的标题 -->
                <div class="rigth">
                    <div class="title">生成以下标题</div>
                    <div class="titleList">
                        <div class="item" v-for="(item, index) in titleList" :key="index">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
export default {
    components: {
        Header
    },
    data() {
        return {
            textarea: '',//文本框内容
            optionsList: [
                {
                    name: '1个',
                    type: 1
                },
                {
                    name: '5个',
                    type: 2
                }, {
                    name: '自定义',
                    type: 3
                }
            ],//标题数量列表
            optionIndex: '',//标题数量选中的index

            fullscreenLoading: false,//加载
            titleList: [
                {
                    title: '整机为钛空灰配色，拥有一定的类肤质触感。在功能 方，整机为钛空灰配色，拥有一定的类肤质触感。在功能 方面，它支持通话录音'
                },

            ],//生成的标题列表
        }
    },
    methods: {
        selectOption(index) {
            this.optionIndex = index
        },
        generate() {

        }
    }
}
</script>
<style lang="less" scoped>
.contentBox {
    margin: 0 20px 20px 20px;
    background-color: #fff;
    border-radius: 20px;
    height: 830px;
}

.headerTitle {
    height: 81px;
    line-height: 81px;
    text-align: center;
    border-bottom: 1px solid #E7E7E7;
    font-size: 24px;
    color: #3D3D3D;
    position: sticky;
    top: 0;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
}

.randomExample {
    padding: 0 20px;
    line-height: 40px;
    font-size: 24px;
    color: #3D3D3D;

    span {
        color: #549AFE;
        cursor: pointer;
    }
}

.allWorks {
    padding: 0 20px;
    display: flex;
    justify-content: right;

    div {
        cursor: pointer;
        padding: 5px 10px;
        font-size: 20px;
        color: #FFFFFF;
        border-radius: 5px;
        background: #549AFE;
    }
}

.themeTtile {
    padding: 0 20px;
    font-size: 24px;
    color: #3D3D3D;
}

.generationRegion {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .left {
        width: 1090px;

        /deep/.el-textarea__inner {
            height: 375px;
        }

        .numberHeadings {
            padding: 0 20px;
            font-size: 24px;
            color: #3D3D3D;

            .title {}

            .options {
                display: flex;
                margin-top: 10px;

                .item {
                    margin-right: 15px;
                    border-radius: 5px;
                    box-sizing: border-box;
                    border: 1px solid #909090;
                    padding: 5px 10px;
                    cursor: pointer;
                }

                .active {
                    border: 1px solid #549AFE;
                }
            }
        }

        .generate {
            margin-left: 20px;
            margin-top: 20px;
            height: 82px;
            line-height: 82px;
            text-align: center;
            border-radius: 10px;
            background: #D8D8D8;
            font-size: 24px;
            color: #fff;
            cursor: pointer;
        }
    }

    .rigth {
        width: 518px;
        height: 520px;
        padding: 20px;
        border: 1px solid #DCDFE6;
        border-radius: 10px;
        scrollbar-width: none;
            /* Firefox */
            -ms-overflow-style: none;
            /* IE 10+ */
            overflow-x: hidden;
            overflow-y: auto;

        .titleList {
           
            font-size: 18px;
            line-height: 25px;

            .item {
                border-bottom: 1px solid #D8D8D8;
                font-size: 20px;
                color: #3D3D3D;
                margin-top: 30px;
                padding-bottom:5px;
            }
        }
    }

}
</style>