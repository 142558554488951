<template>
    <div class="content">
        <el-dialog :show-close="show_close" :visible="userShow" @close="close" width="500px">
            <div class="logo">
                <img v-if="webLogo" :src="webLogo" alt="">
            </div>

            <div class="tabHeader">
                <div class="tab"><button v-for="tab in tabs" :key="tab.id" @click="selectTab(tab.id)"
                        :class="{ active: activeTab === tab.id }">
                        {{ tab.title }}
                    </button>
                </div>
            </div>

            <div v-if="activeTab === 'login'">

                <div class="demo-input-suffix" style="margin-top: 30px;">
                    <el-input placeholder="请输入登录账号" v-model="username">
                    </el-input>
                </div>


                <div class="demo-input-suffix" style="margin-top: 30px;">
                    <el-input show-password placeholder="请输入登录密码" v-model="password">
                    </el-input>
                </div>

                <div class="checkbox" style="margin-top: 30px;">
                    <el-checkbox v-model="checked">我已阅读并同意<span>《用户协议》</span>和<span>《隐私政策》</span></el-checkbox>
                </div>

                <div class="dialog-footer" style="margin-top: 30px;">
                    <div @click="loginBut">登录</div>
                </div>

            </div>

            <div v-if="activeTab === 'signin'">

                <div class="demo-input-suffix" style="margin-top: 30px;">
                    <el-input placeholder="请输入用户名称" v-model="signinUsername">
                    </el-input>
                </div>
                <div class="demo-input-suffix" style="margin-top: 30px;">
                    <el-input show-password placeholder="请输入注册密码" v-model="signinPassword">
                    </el-input>
                </div>
                <div class="demo-input-suffix" style="margin-top: 30px;">
                    <el-input show-password placeholder="请确认注册密码" v-model="verifySigninPassword">
                    </el-input>
                </div>
                <div class="checkbox" style="margin-top: 30px;">
                    <el-checkbox v-model="signinChecked">我已阅读并同意<span>《用户协议》</span>和<span>《隐私政策》</span></el-checkbox>
                </div>

                <div class="dialog-footer" style="margin-top: 30px;">
                    <div @click="signinBut">注册并登录</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { authCodeLogin, getUserInfo, sigin } from '../../../api/api'

export default {
    name: 'Login',
    props: {
        userShow: {
            type: Boolean,
            default: false
        },
        show_close: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            webLogo: '',//LOGO
            activeTab: 'login',
            tabs: [
                { id: 'login', title: '账号登录' },
                { id: 'signin', title: '账号注册' },
            ],
            username: '',
            password: '',
            checked: false,

            signinUsername: '',
            signinPassword: '',
            verifySigninPassword: '',
            signinChecked: false,


            time: null,
            fullscreenLoading: false//加载
        }
    },
    methods: {
        close() {
            this.$emit("getUserShow", false);
        },
        selectTab(tabId) {
            this.activeTab = tabId;
        },
        // 登录
        loginBut: function () {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            that.time = setTimeout(function () {
                if (!that.checked) return that.$message({ message: '请先阅读并同意《用户协议》和《隐私政策》', type: 'warning' });
                if (!that.username) return that.$message({ message: '请输入登录账号', type: 'warning' });
                if (!that.password) return that.$message({ message: '请输入登录密码', type: 'warning' });
                const loading = that.$loading({ lock: true, text: 'Loding', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
                that.login()
                that.time = null;
                loading.close();
            }, 1000)
        },
        // 注册
        signinBut: function () {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
           

            that.time = setTimeout(function () {
                if (!that.signinChecked) return that.$message({ message: '请先阅读并同意《用户协议》和《隐私政策》', type: 'warning' });
                if (!that.signinUsername) return that.$message({ message: '请输入用户名称', type: 'warning' });
                if (!that.signinPassword) return that.$message({ message: '请输入注册密码', type: 'warning' });
                if (that.verifySigninPassword !== that.signinPassword) return that.$message({ message: '两次密码不一致', type: 'warning' });
                const loading = that.$loading({ lock: true, text: 'Loding', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
                that.signin()
                that.time = null;
                loading.close();
            }, 1000)
        },

        // 登录接口
        login() {
            let data = {
                "username": this.username,
                "password": this.password
            }
            authCodeLogin(data).then(res => {
                if (res.data.code == 10000) {
                    localStorage.setItem('token', res.data.data.token);
                    this.getUserInfo()
                    this.$message({ message: '登录成功', type: 'success' });
                    this.$emit('userInfoIs', true)
                    this.username = ''
                    this.password = ''
                    this.close()
                    this.$router.push('/')
                }
            })
        },
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then(res => {
                if (res.data.code == 10000) {
                    localStorage.setItem('userInfo', JSON.stringify(res.data.data));
                    this.$emit("userAvatar", JSON.stringify(res.data.data));
                }
            })
        },

        // 注册接口
        signin() {
            let data = {
                "username": this.signinUsername,
                "password": this.signinPassword
            }
            sigin(data).then(res => {
                if (res.data.code == 10000) {
                    this.$message({ message: '注册成功', type: 'success' });
                    this.username = this.signinUsername
                    this.password = this.signinPassword
                    this.login()
                }
            })
        },
    },
    created() {
        this.$bus.$on('webMessage1', msg => {
            if (msg) {
                let webMessage = JSON.parse(localStorage.getItem('webMessage'))
                this.webLogo = webMessage.website_logo || ''
            } else {
                this.webLogo = require('../../assets/logo.png')
            }
        })

        var webMessage = JSON.parse(localStorage.getItem('webMessage'))
        if(webMessage !==null){
            if (webMessage.website_logo !== '') {
            this.webLogo = webMessage.website_logo
        } else {
            this.webLogo = require('../../assets/logo.png')
        }
        }
       

    }
}
</script>
<style lang="less" scoped>
.content {
    /deep/.el-dialog__body {
        height: 500px !important;
    }

    .tabHeader {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        .tab {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 100px;

            button {
                background: none;
                border: none;
                padding: 15px 0;
                font-size: 22px;
                cursor: pointer;
                transition: background-color 0.3s;
                border-bottom: 2px solid transparent;
                white-space: nowrap;
                color: #939393;
            }

            button.active {
                font-weight: bold;
                border-bottom: 2px solid #3977F3;
            }
        }
    }

    .logo {
        text-align: center;

        img {
            width: 270px;
            height: 67.5px;
        }
    }

    .dialog-footer {
        div {
            background-color: #3977F3;
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
            text-align: center;
            color: #fff;
        }
    }

    .checkbox {
        span {
            color: #3977F3;
        }
    }
}
</style>