<template>
    <div class="content">
        <!-- 轮播 -->
        <div class="block">
            <el-carousel :interval="4000" trigger="click" height="80px">
                <el-carousel-item v-for="(item, index) in dataImg" :key="index">
                    <img style="width: 100%;" :src="item.path" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>

        <!-- 业务选择 -->
        <div class="business">
            <div class="item" @click="businessItem(item, index)" :style="{ background: item.back }"
                v-for="(item, index) in businessList" :key="index">
                <div class="left">
                    <div class="title">{{ item.title }}</div>
                    <div class="text">{{ item.text }}</div>
                </div>
                <div class="right">
                    <img :src="item.img">
                </div>
            </div>
        </div>

        <!-- 更多选择 -->
        <div class="MoreBusiness">
            <div>
                <div class="tabHeader">
                    <div class="item" v-for="(item, index) in tabs" :key="index" @click="selectTab(index)"
                        :class="{ active: activeIndex === index }">
                        {{ item.title }}
                    </div>
                </div>
                <div class="tabContent">
                    <div v-if="activeIndex === 0" class="manageContent">
                        <div class="title">轻松构建有知识、有技能、有人格的新一代交互型数字人，让交流更具新意，体验更加智能 </div>
                        <div class="usage-count">累计交互次数 <span>585678</span> 次</div>
                        <button class="quick-create">快速创建</button>
                    </div>
                    <div v-else-if="activeIndex === 1" class="createContent">
                        <div>上传一段30s-300s的视频，系统可快速克隆出你的形象和动作，轻松打造优质数字人视频</div>
                        <!-- <div class="usage-count">累计交互次数 <span>585678</span> 次</div> -->
                        <div class="information">
                            <div class="left">
                                <div class="top">
                                    <img src="../assets/kefu.png" alt="">累计上传形象克隆
                                </div>
                                <div class="bottom">
                                    15348,84<span>条</span>
                                </div>
                            </div>
                            <div class="right">
                                <div class="top">
                                    <img src="../assets/kefu.png" alt="">累计合成口播视频
                                </div>
                                <div class="bottom">
                                    15348,84<span>条</span>
                                </div>
                            </div>
                        </div>
                        <button class="quick-create">开始创作</button>
                    </div>
                    <div v-else-if="activeIndex === 2" class="manageContent">
                        <div>轻松构建有知识、有技能、有人格的新一代交互型数字人，让交流更具新意，体验更加智能 </div>
                        <div class="usage-count">累计交互次数 <span>585678</span> 次</div>
                        <button class="quick-create">快速创建</button>
                    </div>
                </div>
            </div>
            <div class="preview-area">
                <div v-if="activeIndex === 0"><img src="../assets/video.png" alt=""></div>
                <div v-if="activeIndex === 1"><img src="../assets/live.png" alt=""></div>
                <div v-if="activeIndex === 2"><img src="../assets/chat.png" alt=""></div>
            </div>
        </div>
    </div>
</template>

<script>

import { initialize } from '../../api/api'

export default {
    name: 'Main',
    data() {
        return {
            activeIndex: 0,
            tabs: [
                { id: 'create', title: '数字人直播' },
                { id: 'manage', title: '数字人视频' },
                { id: 'dialogue', title: '数字人对话' }
            ],
            dataImg: [
                {
                    name: '1',
                    path: 'https://cdn.oouu.cc/virtuai/001.jpg'
                },
                {
                    name: '2',
                    path: 'https://cdn.oouu.cc/virtuai/002.jpg'
                },
                {
                    name: '3',
                    path: 'https://cdn.oouu.cc/virtuai/004.jpg'
                }
            ],
            businessList: [
                {
                    title: '数字人形象克隆',
                    text: '还原真人表情动作',
                    img: require('../assets/business1.png'),
                    back: 'linear-gradient(90deg, #AB7BFF 0%, rgba(95, 204, 255, 0.87) 100%)',
                    path: '/Home/digitalMan/digitalCloning'
                },
                {
                    title: '照片数字人克隆',
                    text: '让你的照片会说话',
                    img: require('../assets/business2.png'),
                    back: 'linear-gradient(90deg, #00D9FA 0%, rgba(177, 160, 254, 0.69) 100%)',
                    path: ''
                },
                {
                    title: '交互式数字人',
                    text: '一对一实时对话',
                    img: require('../assets/business3.png'),
                    back: 'linear-gradient(90deg, #7E81FF 0%, rgba(120, 232, 254, 0.84) 100%, #00D5FF 100%)',
                    path: ''
                },
                {
                    title: '声音克隆',
                    text: '上传音频克隆声音',
                    img: require('../assets/business4.png'),
                    back: 'linear-gradient(90deg, rgba(0, 123, 255, 0.69) 0%, rgba(195, 105, 255, 0.59) 100%)',
                    path: '/Home/audio'
                },

            ],
            timer:null
        }
    },
    methods: {
        selectTab(index) {
            this.activeIndex = index;
        },
        businessItem(item, index) {
            if (item.path == '') {
                this.$message({
                    message: '功能开发中，敬请期待！',
                    type: 'success'
                });
            }
            this.$router.push(item.path)
        },


        // 初始化数据
        initialize() {
            initialize().then(res => {
                if (res.data.code == 10000) {
                    // console.log(res.data.data)
                    localStorage.setItem('initializeData', JSON.stringify(res.data.data) );
                }
            })
        },

    },
    created(){
        this.initialize()
    },
    mounted() {
        this.timer = setInterval(() => {
            this.activeIndex += 1;
            if (this.activeIndex > 2) {
                this.activeIndex = 0; // 循环
            }
            //这里放当前数据
        }, 5000);
    },
    // 销毁定时器
    destroyed() {
        clearInterval(this.timer);
        this.timer = null;
    },
}
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    /* 隐藏滚动条 */
    display: none;
}

.content {
    overflow: hidden;
    width: 1580px;
    overflow-x: auto;

    .block {
        .el-carousel {
            border-radius: 10px;
        }

        img {
            height: 80px;
            border-radius: 10px;
        }
    }

    .business {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .item {
            cursor: pointer;
            padding: 20px;
            border-radius: 20px;
            opacity: 1;
            display: flex;
            justify-content: space-between;
            width: 340px;
            height: 98px;

            .left {
                white-space: nowrap;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 28px;
                    color: #fff;
                    // line-height: 80px;
                }

                .text {
                    font-size: 19px;
                    color: #fff;
                    line-height: 30px;

                }
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    transition: all 0.4s ease 0s;

                    width: 127px;
                    height: 109px;
                }
            }
        }

        .item {
            transition: all 0.2s;
        }

        .item:hover {
            img {
                //鼠标悬停时激活
                // opacity: 0.2; //透明度
                transition: 0.2s; //动画过渡的更加顺滑
                transform: scale(1.2); //放大倍数
            }

        }
    }

    .MoreBusiness {
        margin-top: 20px;
        padding: 0 20px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        height: 583px;
        .tabHeader {
            width: 440px;
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            border-bottom: 1px solid #e0e0e0;
            padding-top: 100px;
            margin-left: 100px;
            position: relative;
            .item {
                white-space: nowrap;
                width: 100px;
                text-align: center;
                font-size: 20px;
                cursor: pointer;
                line-height: 50px;
                color: #D8D8D8;
            }
        }

        .tabHeader .item.active {
            background-color: #fff;
            font-weight: bold;
            color: #000000;
            border-bottom: 2px solid #007bff;
        }
        .tabContent {
            width: 440px;
            margin-left: 100px;
            padding:  30px 0;
            .manageContent{
                height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .createContent {
                height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .information {
                    display: flex;
                    justify-content: space-between;

                    .bottom {
                        text-align: center;
                        color: #182337;
                        font-weight: bold;
                        font-size: 22px;

                        span {
                            font-size: 12px;
                        }
                    }

                    img {
                        margin-right: 5px;
                        width: 14px;
                        height: 14px;
                    }
                }
               
            }
        }



        .createContent h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }


        .usage-count span {
            font-weight: bold;
            color: #333;
        }

        .quick-create {
            background-color: #000000;
            color: white;
            border: none;
            width: 100%;
            padding: 12px 24px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s;
            margin-top: 50px;
        }

        // .quick-create:hover {
        //     background-color: #0056b3;
        // }

        .preview-area {
            width: 60%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 50px;
            height: 590px;
            transition: all .5s;
        }

        .preview-area img {
           
            width: 752px;
            height: 386px;
            object-fit: contain;
        }
    }

}
</style>