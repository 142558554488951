<template>
    <div class="myImage">
        <div class="tab">
            <div style="display: flex">
                <div class="item" @click="clickTab(index)"
                    :style="{ background: (tabIndex == index ? item.back : ''), color: (tabIndex == index ? '#fff' : '#000') }"
                    v-for="(item, index) in tab" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <div class="search">
                <div>
                    <el-input placeholder="请输入内容" @change="search" prefix-icon="el-icon-search" v-model="searchValue">
                    </el-input>
                </div>
            </div>
        </div>

        <div class="myList">
            <div class="list" v-if="list.length !== 0">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <imageModule :item="item" :controlsIs="true" :index="index" :newName="newName"
                        @deleteItem="deleteItem" @editName="editName" @videoItem="videoItem" @refreshItem="refreshItem" />
                </div>
                <div>
                    <el-dialog title="重命名" :visible.sync="nameBoxShow" width="20%">
                        <el-input placeholder="请输入名称" v-model="nameValue"></el-input>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="nameBoxShow = false">取 消</el-button>
                            <el-button type="primary" @click="verifyBut">确 定</el-button>
                        </span>
                    </el-dialog>
                </div>
            </div>
            <div class="noData" v-if="list.length == 0">
                <noData />
            </div>
            <div class="paging" v-if="total > 16">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="page"
                    :page-size="16" :total="total" :page-sizes="[16]" @current-change="handlePageChange" />
            </div>
        </div>

        <div>
            <el-dialog :title="playVideoItem.name" :visible.sync="videoShow" :before-close="videoShowBefore"
                width="400px">
                <playVideo :item="playVideoItem" :videoShow="videoShow" />
            </el-dialog>
        </div>
    </div>
</template>
<script>
import imageModule from '../../../components/digitalImageModule/imageModule.vue';
import noData from '../../../components/noData.vue'
import playVideo from '../../../components/playVideo.vue'
import { getMyModelList, editMyMode } from '../../../api/api'
export default {
    components: {
        imageModule,
        noData,
        playVideo
    },
    data() {
        return {
            tab: [
                {
                    name: 'V1克隆',
                    back: 'linear-gradient(90deg, #19B9FB 0%, #92CFE8 100%), linear-gradient(270deg, #757ABF 0%, #8583BE 60%, #EAB0D1 100%), #D8D8D8',
                    type: '1'
                },
                {
                    name: 'V2克隆',
                    back: 'linear-gradient(90deg, #4183F5 0%, #51B1DE 100%',
                    type: '2'
                },
                {
                    name: 'V3克隆',
                    back: 'linear-gradient(90deg, #50C4B8 0%, #29B8FB 100%, #D8D8D8',
                    type: '3'
                },
                {
                    name: 'V4克隆',
                    back: 'linear-gradient(270deg, #3F8CFF 0%, #5376FF 99%), #D8D8D8',
                    type: '4'
                },
                // {
                //     name: 'V5克隆',
                //     back: 'linear-gradient(270deg, #46B2FF 0%, #9E7DFF 99%), #D8D8D8',
                //     type: '5'
                // },
            ],
            tabIndex: '0',//选中的tab的Index
            searchValue: '',//搜索内容
            list: [],
            page: 1,
            nameBoxShow: false,//修改名称弹框
            nameValue: '',//修改名称value
            itemIndex: '',//确认修改的item的index
            newName: '',//修改的名称
            total: '',
            line_id: '1',
            fullscreenLoading: false,//加载

            videoShow: false,
            playVideoItem: {}

        }
    },
    provide() {
        return {
            getMyModelList: this.getMyModelList
        };
    },
    methods: {
        videoItem(e) {
            this.playVideoItem = e
            this.videoShow = true
        },
        videoShowBefore() {
            this.videoShow = false
        },
        refreshItem(e){
            let data = JSON.parse(e)
            let list = this.list
            for(let i=0;i<list.length;i++){
                if(list[i].id == data.id){
                    this.list[i] = data
                }
            }
            this.$forceUpdate();
        },
        clickTab(index) {
            if ( index == 3) {
                this.$message({
                    message: '敬请期待！',
                    type: 'success'
                });
                return
            }
            this.tabIndex = index
            this.line_id = this.tab[index].type
            this.page = 1
            this.getMyModelList()
        },
        // 搜索-仅在input失去焦点或用户按回车触发
        search() {
            this.page == 1
            this.getMyModelList()
        },
        // 删除我的作品
        deleteItem(index) {
            console.log(index, '删除index')
            this.list.splice(index, 1)
        },
        // 修改名称
        editName(index) {
            console.log(index, 'index')
            if (index !== '') {
                this.nameBoxShow = true
            }
            this.itemIndex = index
        },
        // 确认修改名称
        verifyBut() {
            let index = this.itemIndex
            this.newName = this.nameValue
            // 发送接口
            let data = {
                id: this.list[index].id,
                name: this.nameValue
            }
            editMyMode(data).then(res => {
                if (res.data.code == 10000) {
                    this.list[index].name = res.data.data.name
                    this.$message({ message: '修改成功', type: 'success' });
                }
            })
            this.nameValue = ''
            this.nameBoxShow = false
        },
        // 翻页
        handlePageChange(currentPage) {
            this.page = currentPage
            this.getMyModelList()
            // 在此刷新数据
        },
        getMyModelList() {// 获取我的形象
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
            let params = {
                page: this.page,
                page_size: 16,
                line_id: this.line_id,
                name: this.searchValue
            }
            getMyModelList(params).then(res => {
                if (res.data.code == 10000) {
                    this.list = res.data.data
                    this.total = res.data.total
                }
                loading.close();
            })
        },
    },
    created() {
        this.getMyModelList()
    }
}
</script>
<style lang="less" scoped>
.myImage {
    position: relative;

    .tab {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 10px 50px;

        .item {
            width: 107px;
            height: 39.5px;
            line-height: 39.5px;
            text-align: center;
            font-size: 20px;
            cursor: pointer;
            /*悬停变小手的属性*/
            border-radius: 10px;
            background-color: #D8D8D8;
            margin-right: 20px;
            color: #3D3D3D;
        }

        .search {
            display: flex;
            justify-content: right;
            margin-right: 50px;

            div {
                width: 200px;
            }
        }
    }



    .paging {
        position: sticky;
        bottom: 0;
        left: 0;
        height: 50px;

        background-color: #fff;
        width: 100%;
        display: flex;
        justify-content: center;

        .el-pagination {
            margin-top: 10px;
        }
    }
}

.myList {
    height: 678px;
    margin: 0 20px;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .list {
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 380px;
            display: flex;

            justify-content: center;

        }
    }

    .noData {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -120px;
        margin-top: -70px;
    }
}
</style>