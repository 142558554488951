<template>
    <div class="ConsumptionRecord">
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="title_time">
                    <div class="title">{{ item.title }}</div>
                    <div class="time">{{ item.addtime }}</div>
                </div>

                <div class="price">-{{ item.price }}</div>
            </div>
        </div>
    </div>
</template>
<script>
//积分使用记录
import { getIntegralRecord } from '../../api/api'
export default {
    props:{
        page:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            list: [],
            total:0
        }
    },
    methods: {
        getIntegralRecord() {
            let params = {
                page: this.page
            }
            getIntegralRecord(params).then(res => {
                if (res.data.code == 10000) {
                    this.$emit('integralUsageTotal',res.data.total)
                    this.list = res.data.data
                }
            })
        }
    },
    created() {
        this.getIntegralRecord()
    }
}
</script>
<style lang="less" scoped>
.list {
    .item {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .title_time {
            .title {
                font-size: 18px;
                font-weight: bold;
                color: #484848;
            }

            .time {
                font-size: 1626rpx;
                color: #484848;
            }
        }

        .price {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
</style>